html, body {
    scroll-behavior: smooth;
    height: 100%;
}

#root {
    white-space: pre-line;
}

.cover_deceased_pictures {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.size_icon_img_pictures_user {
    height: 32px;
    width: 32px;
    border-radius: 50%;
}

.rounded_img {
    border-radius: 50%;
    height: 100%;
    width: 100%;
}

.around_top {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.full_width {
    width: 100%;
}

.max_full_width {
    max-width: 100%;
}

.mid_width {
    width: 50%;
}

.full_width_height {
    width: 100%;
    height: 100%;
}

.size-menu-icon {
    font-size: 18px;
}

.custom-field-website {
    border-bottom: 1px solid white;
    color: white;
}

.img-box1-website {
    width: 100%;
}

.img-box3-card {
    width: 100%;
}

.img-card-box-2 {
    width: "100%";
    height: 250px;
}

.img-small-card-box-3 {
    width: 45px;
    height: 45px;
    border-radius: 50%;
}

.stars-box-3 {
    height: 18px;
}

.ul-white {
    list-style: none;
    color: white;
    font-size: 24px;
    font-weight: bold;
}

.ul-white li::before {
    content: "\2022";
    color: white;
    font-weight: bold;
    display: inline-block; 
    width: 1em;
    margin-left: -1em;
}

.flex-align {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.img-what-receive {
    max-height: 400px;
}

.link-basic {
    color: white;
}

.wrapper-search-deceased, .wrapper-search-funeral-company, .wrapper-search-user {
    display: flex;
    padding: 8px;
    background-color: #FFFFFF;
    border-radius: 4px;
}

.input-search-deceased {
    grid-gap: 5px;
    gap: 5px;
    background: #FFFFFF;
    border: none;
    outline: none;
}

.input-search-funeral-company {
    grid-gap: 5px;
    gap: 5px;
    border: none;
    outline: none;
}

.img-options {
    width: 40px;
    height: 40px;
    border-radius: 20px;
}

.input-lang {
    padding: 8px;
    border: none;
    background-color: red;
    border-radius: 4px;
}

.flag-lang {
    width: 20px;
    height: 15px;
    margin-right: 8px;
}

.hidden {
    display: none;
}

.disable-a {
    text-decoration: none;
}

@media screen and (max-width: 900px) {
    .img-small-card-box-3 {
        width: 25px;
        height: 25px;
    }

    .stars-box-3 {
        height: 10px;
    }

    .wrapper-search-deceased {
        padding: 12px;
    }

    .around_top {
        border-top-right-radius: 0px;
        border-top-left-radius: 0px;
    }
  }
